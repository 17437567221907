var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-image", {
        staticStyle: { width: "100px", height: "100px" },
        attrs: { src: _vm.url, "preview-src-list": _vm.srcList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }