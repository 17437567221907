<script>
import Form, { formCreate } from '../../../../../components/form';
import ExecuteResult from './component/execute_result';
import ExamplePicture from './component/example_picture';
import DesplayDemand from './component/display_demand';
import request from '../../../../../utils/request';

formCreate.component('executeResult', ExecuteResult);
formCreate.component('examplePicture', ExamplePicture);
formCreate.component('displayDemand', DesplayDemand);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('sfaCenter_visitManage_displayInventoryDetail_form');
    // rule.forEach((item) => {
    //   const v = item;
    //   v.props = {
    //     ...item.props,
    //     disabled: false,
    //     readonly: true,
    //   };
    // });
    // 获取详情
    const requestUrl = `/sfa/sfaVisitStepActivityExecutionController/findVisitStepDisplayActivityExecutionDetailEs?id=${this.formConfig.id}`;
    const { result } = await request.post(requestUrl);
    this.setValue(result); // 基础信息
    const activityRequireName = []; // 活动要求
    result.activityRequireReqVoList.forEach((item) => {
      activityRequireName.push(item.activityRequireName);
    });
    // 示例图片
    const examplePicture = result.displayContent.pictureList;
    // 活动照片
    const urlList = [];
    result.displayContent.pictureList.forEach((item) => {
      urlList.push(item.urlPathPrefix + item.urlPath);
    });
    // 陈列要求
    const displayDemand = result.displayContent.activityProductList.map((item) => ({
      productLevel: '1',
      productCode: item.productCode,
      productName: item.productName,
    }));
    // 执行结果
    const executeResult = result.activityRequireReqVoList || [];
    // 活动要求
    this.setValue({
      activityRequireName: activityRequireName.join('、'), // 活动照、门头照、陈列照
      examplePicture, // 示例图片
      displayDemand, // 陈列要求
      executeResult, // 陈列要求图片
    });
  },
};
</script>
