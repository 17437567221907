<script>
import TablePage from '../../../../../components/table_page';
import Form from './form';
import request from '../../../../../utils/request';
import * as utils from '../../../utils/index';

export default {
  extends: TablePage,
  name: 'table-component',
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaVisitStepActivityExecutionController/findVisitStepDisplayActivityExecutionEsList',
      paramsProps: {
        stepCode: 'sfa_step_code_display',
      },
      tableData: [],
      options: [],
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    cellClick({ row, column }) {
      if (column.property === 'activityName') this.viewDetail(row);
    },
    // 查看详情
    viewDetail(row) {
      this.formConfig = {
        type: 'view',
        id: row.id,
      };
      this.modalConfig.title = '查看';
      this.openFull();
    },
    afterGetList() {
      if (this.tableData.length > 0) { // 给活动频率加单位
        const newTableData = this.tableData.map((item) => ({
          ...item,
          activityFrequency: `${item.activityFrequency} 天/次`,
        }));
        this.tableData = newTableData;
      }
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_visitManage_displayInventoryDetail');
  },
};
</script>
