<template>
  <div>
    <el-image
      style="width: 100px; height: 100px"
      :src="url"
      :preview-src-list="srcList"
    ></el-image>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        this.url = val[0].urlPathPrefix + val[0].urlPath;
        this.srcList = val.map((item) => item.urlPathPrefix + item.urlPath);
      },
    },
  },
  data() {
    return {
      url: '', // 显示的图片
      srcList: [], // 预览的图片
    };
  },
};
</script>
